<template>
  <main>
    <div class="form">
      <div class="modalites">
        <h2>Remplissez le formulaire ci-dessous :</h2>
      </div>
      <Form
        :class="'form_step' + step"
        id="monform"
        :form_id="form_id"
        :server="server"
        v-on:results="getResult"
        v-on:step-up="checkStep"
        v-on:step-down="checkStep"
        v-on:form_submit_loading="loading"
      />
    </div>
    <div class="captcha">
      This site is protected by reCAPTCHA and the Google<br />
      <a href="https://policies.google.com/privacy" target="_blank"
        >Privacy Policy</a
      >
      and
      <a href="https://policies.google.com/terms" target="_blank"
        >Terms of Service</a
      >
      apply.
    </div>
  </main>
</template>
<script>
var consts = require("../config");

export default {
  name: "home",
  data: function() {
    return {
      step: 0,
      game_id: consts.GAME_ID,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  methods: {
    gohome() {
      this.$router.push("/");
    },
    loading(value) {
      if (value === true) {
        this.$swal({
          allowOutsideClick: false,
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    checkStep() {
      this.step = this.step++;
    },
    getResult(result) {
      if (result._id) {
        window.sub_id = result._id + "|" + result.uid;
        this.$swal.close();
        this.$router.push("/done");
      } else {
        alert("Erreur interne");
      }
    },
  },
  mounted() {},
  created: function() {},
};
</script>